var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7Dy1fK2nlvTYX1dKFMfvQ"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { initSentry } from '@akinon/next/sentry';

async function initializeSentry() {
  const response = await fetch('/api/sentry', { next: { revalidate: 0 } });
  const data = await response.json();

  const options = {
    dsn: data.dsn,
    integrations: [],
    tracesSampleRate: 1.0
  };

  initSentry('Client', options);
}

initializeSentry();
